import React, { useState,useEffect } from 'react';
import slide1 from '../img/slider/slide2.JPG';
import slide2 from '../img/slider/slide1.JPG';
import slide3 from '../img/slider/slide3.jpg';

const CarouselComponent = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [timer, setTimer] = useState(0);

  const slides = [
    {
      image: slide1,
      content: (
        <>
            <h1 className="wow fadeInDown heading" data-wow-delay=".4s">3. Edition des Komponist*Innen Musikfestivals</h1>
            <h1 className="wow fadeInDown heading heading2" data-wow-delay=".4s" >23. - 25. November 2024</h1>
            <a href="#Ticketreservierung" className="fadeInLeft wow btn btn-common btn-lg" data-wow-delay=".6s" >Ticket reservieren</a>
        </>
      ),
    },
    {
      image: slide2,
      content: (
        <>
            <h1 className="wow bounceIn heading" data-wow-delay=".7s">Internationale Künstler*innen</h1>
            <a href="#Künstler*Innen" className="fadeInUp wow btn btn-common btn-lg" data-wow-delay=".8s">Mehr erfahren</a>
        </>
      ),
    },
    {
      image: slide3,
      content: (
        <>
            <h1 className="wow fadeInUp heading" data-wow-delay=".6s">Reservieren sie jetzt ihr ticket!</h1>
            <a href="#Ticketreservierung" className="fadeInUp wow btn btn-common btn-lg" data-wow-delay=".8s">Ticket reservieren</a>
        </>
      ),
    },
  ];

  const resetTimer = () => {
    setTimer(0);
  };

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  const nextSlide = () => {
    resetTimer();
    setActiveIndex((activeIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    resetTimer();
    setActiveIndex((activeIndex - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
      if (timer >= 10) {
        nextSlide();
      }
    }, 1000); 

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  return (
    <div id="main-slide" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        {slides.map((slide, index) => (
              <div key={index}
                  className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
                  style={{ backgroundImage: `url(${slide.image})` }} >
                      <div className="carousel-caption">
                              <div className="container">
                                  <div className="row">
                                      <div className="col-lg-12">
                                      {slide.content}
                                      </div>
                                  </div>
                              </div>
                      </div>
                  
              </div>

        ))}
      </div>
      <div className="carousel-indicators2">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`indicator ${index === activeIndex ? 'active' : ''}`}
            onClick={() => handleSlideChange(index)}
          ></div>
        ))}
      </div>
      <a className="carousel-control-prev"  role="button" data-slide="prev" onClick={prevSlide}>
      <span className="carousel-control" aria-hidden="true"><i className="lni-chevron-left"></i></span>
            <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" role="button" data-slide="next" onClick={nextSlide}>
            <span className="carousel-control" aria-hidden="true"><i className="lni-chevron-right"></i></span>
            <span className="sr-only">Next</span>
        </a>
    </div>
  );
};

export default CarouselComponent;